.title{
  text-decoration: none;
  color: #305090;
}

a{
  text-decoration: none;
}

.negrita{
  font-weight: bold;
}