img{
    width: 240px;
}

/* .bg {
    background-image: url("../../pages/img/Rectangle\ 6bg.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  } */